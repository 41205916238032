import React from 'react';
import './Repositories.scss';

const RepositoriesLayout = () => (
    <div className='repositories'>
        <h1>Repositories Scene</h1>
    </div>
);

export default RepositoriesLayout;
